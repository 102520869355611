// Generated by Framer (90417e1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./VUkDEwoLf-0.js";
import * as localizedValues1 from "./VUkDEwoLf-1.js";

const cycleOrder = ["mEZ9JfpBj", "bASCnB6bY"];

const serializationHash = "framer-uKQK3"

const variantClassNames = {bASCnB6bY: "framer-v-19ogzgf", mEZ9JfpBj: "framer-v-hgzoow"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {qFQHIOpJU: localizedValues1, VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"slide-1": "mEZ9JfpBj", "slide-2": "bASCnB6bY"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mEZ9JfpBj"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mEZ9JfpBj", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-hgzoow", className, classNames)} data-framer-name={"slide-1"} layoutDependency={layoutDependency} layoutId={"mEZ9JfpBj"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({bASCnB6bY: {"data-framer-name": "slide-2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1du7hgj"} layoutDependency={layoutDependency} layoutId={"ZafB6Jq13"} style={{backgroundColor: "rgba(214, 214, 214, 0.5)", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200}}/><motion.div className={"framer-1g4147z"} layoutDependency={layoutDependency} layoutId={"hnVywvr5P"} style={{backgroundColor: "rgba(97, 97, 97, 0.5)", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200}}/><motion.div className={"framer-1bnorx7"} layoutDependency={layoutDependency} layoutId={"RPXX6Iwly"} style={{backgroundColor: "rgba(97, 97, 97, 0.5)", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200}}/><motion.div className={"framer-1x5btl2"} layoutDependency={layoutDependency} layoutId={"GEBAGakEG"} style={{backgroundColor: "rgba(97, 97, 97, 0.5)", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200}}/><motion.div className={"framer-qz62f5"} layoutDependency={layoutDependency} layoutId={"LdG9BtF18"} style={{backgroundColor: "rgba(97, 97, 97, 0.5)", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-uKQK3.framer-1x5q7dv, .framer-uKQK3 .framer-1x5q7dv { display: block; }", ".framer-uKQK3.framer-hgzoow { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 315px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 48px; }", ".framer-uKQK3 .framer-1du7hgj { flex: none; height: 50px; overflow: visible; position: relative; width: 10px; }", ".framer-uKQK3 .framer-1g4147z, .framer-uKQK3 .framer-1bnorx7, .framer-uKQK3 .framer-1x5btl2, .framer-uKQK3 .framer-qz62f5 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 10px); overflow: visible; position: relative; width: 10px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-uKQK3.framer-hgzoow { gap: 0px; } .framer-uKQK3.framer-hgzoow > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-uKQK3.framer-hgzoow > :first-child { margin-top: 0px; } .framer-uKQK3.framer-hgzoow > :last-child { margin-bottom: 0px; } }", ".framer-uKQK3.framer-v-19ogzgf .framer-1du7hgj { order: 1; }", ".framer-uKQK3.framer-v-19ogzgf .framer-1g4147z { order: 0; }", ".framer-uKQK3.framer-v-19ogzgf .framer-1bnorx7 { order: 2; }", ".framer-uKQK3.framer-v-19ogzgf .framer-1x5btl2 { order: 3; }", ".framer-uKQK3.framer-v-19ogzgf .framer-qz62f5 { order: 4; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 315
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"bASCnB6bY":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVUkDEwoLf: React.ComponentType<Props> = withCSS(Component, css, "framer-uKQK3") as typeof Component;
export default FramerVUkDEwoLf;

FramerVUkDEwoLf.displayName = "progress-bar-slider";

FramerVUkDEwoLf.defaultProps = {height: 315, width: 48};

addPropertyControls(FramerVUkDEwoLf, {variant: {options: ["mEZ9JfpBj", "bASCnB6bY"], optionTitles: ["slide-1", "slide-2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerVUkDEwoLf, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})